import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
  getLanguage,
  loadMessages,
  saveLocaleMessages,
} from '../util/js/languageConfig'
import ElementLocale from 'element-ui/lib/locale'
import en from './langs/en'
import zh from './langs/zh'
import de from './langs/de'

Vue.use(VueI18n)

const dynamicMessages = loadMessages()

const localMessage = {
  EN: {
    ...en,
  },
  DE: {
    ...de,
  },
  'zh-CN': {
    ...zh,
  },
}
// console.log('de:', de)

Vue.prototype.$trans = function (key, defaultValue) {
  const translation = this.$t(key)
  return translation == '' || translation === key ? defaultValue : translation
}

const i18n = new VueI18n({
  locale: getLanguage() || 'zh-CN',
  fallbackLocale: 'zh-CN', //如果当前语种不存在时，默认设置当前语种
  messages: { ...localMessage, ...dynamicMessages },
  // messages: localMessage,
  silentTranslationWarn: true, //去除国际化翻译警告
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

//将接口中返回有携带翻译的内容 保存
export function loadLocaleMessages(messages, path) {
  Object.keys(messages).forEach((locale) => {
    const newMessages = messages[locale]
    const existingMessages = i18n.getLocaleMessage(locale)

    if (path) {
      const obj = {}
      obj[`${path}`] = newMessages
      const mergeMessages = { ...existingMessages, ...obj }
      i18n.mergeLocaleMessage(locale, mergeMessages)
    } else {
      const mergeMessages = { ...existingMessages, ...newMessages }
      i18n.mergeLocaleMessage(locale, mergeMessages)
    }
  })
  saveLocaleMessages(i18n.messages)
}

export default i18n
