import Vue from 'vue';

import Swiper from "vue-awesome-swiper"
// import "swiper/swiper.min.css"
import "../util/css/swiper.min.css"
import * as filter from "./js/filter.js"
import "./js/methods.js"
import "./js/element.js"
import "./js/element.js"
import "./css/base.css"
import "./css/common.css"
import "./css/public.css"
import VueLazyload from 'vue-lazyload'
import { Base64 } from 'js-base64'
import { message } from '@/util/js/resetMessage.js';
Vue.prototype.$Base64 = Base64


Vue.use(VueLazyload)
Vue.use(Swiper);
Vue.prototype.$message = message;
Object.keys(filter).forEach(key => {
    Vue.filter(key, filter[key])
})