import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const zh = {
  test: {
    message: '测试',
  },
  ...zhLocale,
}

export default zh
