function decimalDispose(a){
    let b = a || 0;
    b = b * 100
    return Math.round( b ) / 100
}

function product_int(a) {
    let b = a || 0;
    if(typeof b !== 'number'){
        b = parseFloat(b);
    }
    b = decimalDispose(b)
    let pointReg = /\./;
    return pointReg.test(b) ? b.toString().replace(/([0-9]+\.)[0-9]*/,'$1') : b+"."
}

function product_float(a) {
    let b = a || 0;
    if(typeof b !== 'number'){
        b = parseFloat(b);
    }
    b = decimalDispose(b)
    let pointReg = /\./;
    let c = pointReg.test(b) ? b.toString().replace(/^\d+\.(\d{0,2})\d*$/g,"$1") : "00";
    return c.length == 2 ? c : c + "0"
    // let pointReg = /\./;
    // let c = pointReg.test(b) ? b.toString().split(".")[1] : "00"
    
    // let d = c.slice(0,2)
    // return pointReg.test(b) ? d.length==2?d:d+"0" : "00"
}
function keepOutPhone(val){
    if(val){
        val.toString();
        return val.replace(/^(\d{3})\d{4}(\d{4})$/,'$1****$2')
    }
    return false
}

function normalizeDate(val){
    if(val){
        let str = val.slice(0,10);
        let arr = str.split("-");
        let a = arr.reduce((a,b)=>a+b+"-","");
        return a.slice(0,a.length-1);
    }
    return "2000:00:00"
}
export { product_int, product_float ,keepOutPhone,normalizeDate }