const routes = [
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/views/layout/layout.vue'),
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/index/index.vue'),
      },
      {
        path: 'gallery',
        name: 'gallery',
        meta: {
          title: '画廊',
        },
        component: () => import('@/views/index/gallery.vue'),
      },
      {
        path: 'artist',
        name: 'artist',
        meta: {
          title: '艺术家',
        },
        component: () => import('@/views/index/artist.vue'),
      },
      {
        path: 'artist/detail/:id',
        name: 'artistDetail',
        meta: {
          title: '艺术家详情页',
        },
        component: () => import('@/views/index/artist.detail.vue'),
      },
      {
        path: 'shop/detail/:id',
        name: 'shopDetail',
        meta: {
          title: '商品详情',
        },
        component: () => import('@/views/index/shop.detail.vue'),
      },
      {
        path: 'collection',
        name: 'collection',
        meta: {
          title: '我的收藏',
          register: true,
        },
        component: () => import('@/views/collection/index.vue'),
      },
      //  {
      //     path: "shop",
      //     name: "shop",
      //     meta: {
      //         title: "购物车"
      //     },
      //     component: () =>
      //         import ("@/views/index/shop.vue")
      // },
      {
        path: 'about',
        name: 'about',
        meta: {
          title: '关于',
        },
        component: () => import('@/views/static/about.vue'),
      },
      {
        path: 'question',
        name: 'question',
        meta: {
          title: '问题',
        },
        component: () => import('@/views/static/question.vue'),
      },
      {
        path: 'policy',
        name: 'policy',
        meta: {
          title: '隐私政策',
        },
        component: () => import('@/views/static/policy.vue'),
      },
      {
        path: 'reg',
        name: 'reg',
        meta: {
          title: '服务条款',
        },
        component: () => import('@/views/static/reg.vue'),
      },
      {
        path: 'relation',
        name: 'relation',
        meta: {
          title: '联系我们',
        },
        component: () => import('@/views/static/relation.vue'),
      },
      {
        path: 'mine',
        name: 'mine',
        meta: {
          title: '我的',
          register: true,
        },
        component: () => import('@/views/mine/mine.vue'),
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order/order.vue'),
        redirect: '/order/index',
        children: [
          {
            path: 'index',
            name: 'orderIndex',
            meta: {
              title: '订单首页',
              register: true,
            },
            component: () => import('@/views/order/index.vue'),
          },
          {
            path: 'index/accomplish/detail',
            name: 'accomplishDetail',
            meta: {
              title: '已完成详情',
              register: true,
            },
            component: () => import('@/views/order/detail/one.vue'),
          },
          {
            path: 'index/unpaid/detail',
            name: 'unpaidDetail',
            meta: {
              title: '待支付详情',
              register: true,
            },
            component: () => import('@/views/order/detail/two.vue'),
          },
          {
            path: 'new',
            name: 'orderNew',
            meta: {
              title: '新建订单',
              register: true,
            },
            component: () => import('@/views/order/new.vue'),
          },
          {
            path: 'openTicket',
            name: 'openTicket',
            meta: {
              title: '开具发票',
              register: true,
            },
            component: () => import('@/views/order/openTicket.vue'),
          },
        ],
      },
      {
        path: '/address',
        redirect: '/address/index',
        component: () => import('@/views/address/address.vue'),
        children: [
          {
            path: 'index',
            name: 'addressIndex',
            meta: {
              title: '收货地址',
              register: true,
            },
            component: () => import('@/views/address/index.vue'),
          },
          {
            path: 'new',
            name: 'addressNew',
            meta: {
              title: '新建收货地址',
              register: true,
            },
            component: () => import('@/views/address/new.vue'),
          },
        ],
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/setting/setting.vue'),
        redirect: '/setting/index',
        children: [
          {
            path: 'index',
            name: 'settingIndex',
            meta: {
              title: '设置',
              register: true,
            },
            component: () => import('@/views/setting/index.vue'),
          },
          {
            path: 'tel',
            name: 'settingTel',
            meta: {
              title: '设置手机号码',
              register: true,
            },
            component: () => import('@/views/setting/tel.vue'),
          },
          {
            path: 'authentication',
            name: 'authentication',
            meta: {
              title: '认证实名信息',
              register: true,
            },
            component: () => import('@/views/setting/authentication.vue'),
          },
          {
            path: 'email',
            name: 'settingEmail',
            meta: {
              title: '绑定邮箱',
              register: true,
            },
            component: () => import('@/views/setting/email.vue'),
          },
        ],
      },
      {
        path: 'discount',
        redirect: '/discount/index',
        component: () => import('@/views/discount/discount.vue'),
        children: [
          {
            path: 'index',
            name: 'discountIndex',
            meta: {
              title: '优惠券',
              register: true,
            },
            component: () => import('@/views/discount/index.vue'),
          },
          {
            path: 'notutilize',
            name: 'discountNotutilize',
            meta: {
              title: '已使用',
              register: true,
            },
            component: () => import('@/views/discount/notutilize.vue'),
          },
          {
            path: 'disabled',
            name: 'discountDisabled',
            meta: {
              title: '已过期',
              register: true,
            },
            component: () => import('@/views/discount/disabled.vue'),
          },
          {
            path: 'convert',
            name: 'convert',
            meta: {
              title: '兑换',
              register: true,
            },
            component: () => import('@/views/discount/convert.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录页',
    },
    component: () => import('@/views/login/login.vue'),
  },
]

export { routes }
