const lang = 'shangsf_language'

export function setLanguage(language) {
  var tmp = language || 'zh-CN'
  return localStorage.setItem(lang, tmp)
}

export function getLanguage() {
  return localStorage.getItem(lang)
}

export function removeLanguage() {
  localStorage.removeItem(lang)
}

export function getLanguageShow() {
  var language = localStorage.getItem(lang)
  let res = '中文'
  if (language == 'zh-CN') {
    res = '中文'
  } else if (language == 'EN') {
    res = 'English'
  } else if (language == 'DE') {
    res = 'Deutsch'
  } else {
    res = '中文'
  }
  return res
}

export function saveLocaleMessages(messages) {
  localStorage.setItem('localeMessages', JSON.stringify(messages))
}

export function loadMessages() {
  const messages = localStorage.getItem('localeMessages')
  return messages ? JSON.parse(messages) : {}
}
