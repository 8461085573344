import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: true,
        userId:""
    },
    mutations: {
        changeLogin(state, bol) {
            if(bol){
                state.userId = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).id : "";
            }else{
                state.userId = "";
            }
            state.isLogin = bol;
        }
    },
    actions: {},
    modules: {
    }
})