import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './util/main.config.js'
import i18n from './i18n/i18n.js'

import Meta from 'vue-meta'
Vue.use(Meta)

Vue.config.productionTip = false

Vue.use(i18n)
export default i18n
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
