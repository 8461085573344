<template>
  <router-view />
</template>
<script>
export default {
  name: '',
  metaInfo: {
    title: '上殊房',
    meta: [
      { name: 'keywords', content: '上殊房' },
      {
        name: 'description',
        content:
          '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前',
      },
    ],
  },
  mounted() {
    let cookie = document.cookie.split('=')[1]
    if (cookie) {
      this.$store.commit('changeLogin', true)
    } else {
      this.$store.commit('changeLogin', false)
    }
  },
}
</script>
<style></style>
