import Vue from 'vue'
// 将内容复制到剪切面板上
function copyGroupId(groupId) {
  //创建一个新组件
  let oInput = document.createElement('input')
  //给新组件赋值
  oInput.value = groupId
  //添加新节点到页面body中
  document.body.appendChild(oInput)
  //选择对象
  oInput.select()
  //对选择对象的值进行复制到浏览器中
  document.execCommand('Copy')
  // this.$toast.success("复制成功");
  this.$message({
    message: '复制成功',
    type: 'success',
  })
  //删除新节点(重置操作)
  document.body.removeChild(oInput)
}

// 管理cookie
var cookieMethods = {
  // 设置cookie
  set: (name, value, day) => {
    const date = new Date()
    date.setDate(date.getDate() + day)
    document.cookie = name + '=' + value + ';expires=' + date + '; path=/'
  },
  // 获取cookie
  get: (key) => {
    var arr = document.cookie.split('; ')
    for (var i = 0; i < arr.length; i++) {
      var arr1 = arr[i].split('=')
      if (arr1[0] == key) {
        return arr1[1]
      }
    }
    return ''
  },
  // 删除cookie
  remove: (name) => {
    cookieMethods.set(name, '', -1)
  },
}

var storageMethods = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get(key) {
    return JSON.parse(localStorage.getItem(key))
  },
  remove(key) {
    localStorage.removeItem(key)
  },
}

// 退出登录
export function logoutHandleMethods(path, toPath, message) {
  // cookieMethods.remove('token')
  // storageMethods.remove('user')
  // this.$store.commit('changeLogin', false)
  console.log('path：', path, toPath, message)
  this.$router.history.push({
    path: path ? path : '/',
    query: {
      path: toPath ? toPath : '/',
    },
  })
  this.$message.success(message || this.$trans('已退出登录', '已退出登录'))
}

export function loginHandleMethods(cookie, storage) {
  cookieMethods.set(cookie.name, cookie.value, cookie.day)
  storageMethods.set(storage.key, storage.value)
  this.$store.commit('changeLogin', true)
  this.$router.history.push({
    path: this.$route.query.path ? this.$route.query.path : '/',
    query: {
      ...this.$route.query,
    },
  })
}

export function getUserInfo(key) {
  return storageMethods.get(key)
}

export { cookieMethods }
Vue.prototype.copyGroupId = copyGroupId
Vue.prototype.loginHandleMethods = loginHandleMethods
Vue.prototype.logoutHandleMethods = logoutHandleMethods
Vue.prototype.cookieMethods = cookieMethods
Vue.prototype.getUserInfo = getUserInfo
