import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
  上殊房: 'Shangshu Room',
  首页: 'Homepage',
  画廊: 'Gallery',
  艺术家: 'Artist',
  我的: 'My',
  关于: 'About',
  问题: 'Question',
  联系我们: 'Contact Us',
  请输入要查询的内容: 'Please enter the content you wish to query.',
  查看作品: 'View works',
  继续阅读: 'Continue reading',
  上殊房携手知名艺术家共同制作:
    'Shangshu Room collaborates with renowned artists for joint productions.',
  让我们走进每个作品背后的故事:
    "Let's delve into the stories behind each piece.",
  浏览全部: 'Browse all',
  推荐: 'Recommendations',
  筛选: 'Filter',
  重置: 'Reset',
  保存: 'Save',
  上殊房背后的艺术家: 'The Artists Behind Shangshu Room',
  猜你喜欢: 'Recommended for you',
  加入购物车: 'Add to cart',
  立即购买: 'Buy now',
  多维度展示: 'Multi-dimensional display',
  '全方位的、立体的体验视觉之美':
    'A comprehensive, three-dimensional experience of visual beauty',
  品质保证: 'Quality assurance',
  限量发售: 'Limited edition release',
  同多位艺术家合作: 'Collaborate with multiple artists',
  携手多位艺术家共同打造更多精美作品:
    'Join hands with multiple artists to create more exquisite works.',
  订阅我们的公众号: 'Subscribe to our public account.',
  更多精彩上新第一时间通知到你:
    'Notify you of more exciting new arrivals first-hand.',
  信息: 'Information',
  服务条款: 'Terms of service',
  隐私政策: 'Privacy policy',
  您的购物车还是空的: 'Your shopping cart is still empty.',
  继续浏览: 'Continue browsing',
  已售罄: 'Sold out.',
  作者: 'Author',
  'buy now': 'buy now',
  用户登录: 'User login',
  手机号: 'Phone number',
  验证码: 'Verification code',
  登录: 'Log in',
  登录即代表阅读并同意用户使用协议与隐私政策:
    'Logging in signifies reading and agreeing to the user agreement and privacy policy.',
  有货: 'In stock',
  '请选择 尺寸': 'Please select size.',
  购买数量: 'Purchase quantity',
  尺寸: 'Size',
  '本店部分商品为数字产品，一经购买，概不退换':
    'Some items in our store are digital products, once purchased, they cannot be returned or exchanged.',
  共计: 'Total',
  '￥': '',
  您的购物车内有: 'You have ',
  件商品: ' items in your shopping cart.',
  设置: 'Settings',
  我的订单: 'My orders',
  优惠券: 'Coupon',
  我的收藏: 'My favorites',
  收货地址: 'Shipping address',
  在线客服: 'Online customer service',
  客服: 'Customer service',
  退出: 'Log out',
  全部: 'All',
  待支付: 'Pending payment',
  待发货: 'Pending shipment',
  已发货: 'Shipped',
  已完成: 'Completed',
  兑换: 'Redeem',
  未使用: 'Unused',
  已使用: 'Used',
  已失效: 'Expired',
  请输入您的兑换码兑换线上优惠券:
    'Please enter your redemption code to redeem the online coupon.',
  请输入兑换码: 'Please enter the redemption code',
  全选: 'Select all',
  我的头像: 'My avatar',
  修改昵称: 'Change nickname',
  性别: 'Gender',
  年龄: 'Age',
  实名认证: 'Real-name authentication',
  绑定邮箱: 'Bind email',
  退出登录: 'Log Out',
  取消输入: 'Cancel input',
  请输入您的邮箱: 'Please enter your email',
  提交信息: 'Submit information',
  杭州寸间大造文化发展有限公司:
    'Hangzhou CunJian Cultural Development Co., Ltd.',
  '联系电话：0571-89996203': 'Contact Phone: 0571-89996203',
  '传真：0571-89996203': 'Fax: 0571-89996203',
  '邮箱：gfxyzcosmos@gmail.com': 'Email: gfxyzcosmos@gmail.com',
  '网站：https://shangshuroom.com': 'Website: https://shangshuroom.com',
  '当我订购艺术品时,我会得到什么?':
    'What will I receive when I order an artwork?',
  '您会得到一个该艺术品的实物产品，产品会以精美的包装快递或者定制配送到您手里。':
    'ou will receive a physical product of the artwork, which will be delivered to you in exquisite packaging via courier or custom delivery.',
  '在哪里可以看到我的优惠券？': 'Where can I see my coupons?',
  '注：如果您没有看见已领取的优惠券，可能因为：您领取时使用的账号与您本次登录的账号不一致。您之前领取的优惠券已过期/已使用。':
    'Note: If you do not see the coupons you have received, it may be because: The account you used to receive the coupon is different from the account you logged in with this time. The coupon you previously received has expired/been used.',
  '优惠券如何使用？': 'How to use coupons?',
  '1.优惠券分为：满减优惠券、现金券和折扣券。满减券需满足指定金额可用；':
    '1. Coupons are divided into: discount coupons, cash coupons, and discount vouchers. Discount coupons can be used when a specified amount is met;',
  '2.单笔订单只能使用1张优惠券，不支持同时使用多张，用券后差额不找零，不兑换现金。':
    '2. Only one coupon can be used per order. Multiple coupons cannot be used simultaneously. The difference will not be refunded in cash if the coupon is used.',
  '3. 使用优惠券的订单若产生退货，需订单内所有商品均退货退款成功，且优惠券仍在有效期内，可返回优惠券，退回后的优惠券有效期不变。':
    '3. If an order using a coupon is returned, all items in the order must be returned successfully, and the coupon must still be within its validity period to be returned. The validity period of the returned coupon remains unchanged.',
  '* 若只申请部分退款且退款成功，优惠券将不予退回。':
    'If only a partial refund is applied for and successfully refunded, the coupon will not be returned.',
  '* 退回优惠券如已过期，则不再补发。':
    '：If the returned coupon has expired, it will not be reissued.',
  '4.请在有效期内使用优惠券，未使用的优惠券过期后，将自动作废。':
    '4. Please use the coupon within the validity period. Unused coupons will automatically become invalid after expiration.',
  '【上殊房】——听见艺术最细腻的声音':
    '【Shangshu Room】—— Hearing the most delicate sounds of art',
  发票类型: 'Invoice type',
  个人或事业单位: 'Individual or business entity',
  企业: 'Company',
  填写需要开具发票的企业名称: 'Enter the company name for the invoice',
  纳税人识别号: 'Taxpayer identification number (TIN)',
  '请输入您的邮箱,稍后我们会将发票发送至您的邮箱':
    'Please enter your email, we will send the invoice to your email later',
  请输入注册地址: 'Enter the registered address',
  请输入开户行: 'Enter the bank name',
  请输入开户账号: 'Enter the bank account number',
  开户账号: 'Bank account number',
  开户行: 'Bank name',
  注册地址: 'Registered address',
  接收邮箱: 'Receiving email',
  税号: 'Tax number',
  发票抬头: 'Invoice title',
  抬头类型: 'Invoice title type',
  增值税电子普通发票: 'VAT general electronic invoice',
  不开发票: 'Do not issue invoice',
  安装费: 'Installation fee',
  配送服务: 'Delivery service',
  微信: 'WeChat',
  支付宝: 'Alipay',
  支付方式: 'Payment method',
  实付: 'Amount paid',
  查看详情: 'View details',
  取消订单: 'Cancel order',
  立即支付: 'Pay now',
  订单编号: 'Order number',
  小计: 'Subtotal',
  开具发票: 'Issue invoice',
  '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前。而“殊”则是特别，将每一件作品的殊胜之处，背后的细腻情感传达到您的心里。众多艺术家们将与上殊房一起缔造一个更丰富多彩的多维空间，呈现众多杰出艺术作品的进行时和在新的宇宙空间里的将来时。希望喜欢、热爱艺术的你，成为上殊房的一份子，一起去聆听艺术最细腻的声音。将更多的欢喜和慰藉带给自己，也带给身边的人。':
    '“Shang” means to start, act, and be proactive. We are committed to presenting each piece of art in a multidimensional way before your eyes. “Shu” means special, conveying the unique aspects and delicate emotions behind each work to your heart.Many artists will join Shangshu Room to create a more colorful multidimensional space, presenting numerous outstanding artworks in progress and in the future space of a new universe. We hope that you, who love and appreciate art, will become a part of Shangshu Room, to listen to the most delicate sounds of art together. Bring more joy and comfort to yourself and to those around you.',
  确定要删除该商品吗: 'Are you sure you want to delete this item?',
  没有搜索到匹配的数据: 'No matching data found.',
  '领取优惠券之后，请您登录上殊房，点击“我的”，进入“优惠券”，​查看您已领取的优惠券。我的卡券分为三个模块：未使用、已使用、已失效。':
    'After claiming the coupon, please log in to Shangshuroom, click "My", go to "Coupons" to view your claimed coupons. My Coupons are divided into three sections: Unused, Used, and Expired.',
  '移动电话：15925933033/18605077656': 'Mobile phone: 15925933033/18605077656',
  '地址：浙江省杭州市上城区越秀维多利中心15A02':
    'Address: Room 15A02, Yuexiu Vitoria Center, Shangcheng District, Hangzhou City, Zhejiang Province',
  请先完成实名: 'Please complete the real-name verification first.',
  请先支付该订单: 'Please pay for this order first.',
  '确认订单信息错误,请稍后再试':
    'Order information error confirmed，please try again later.',
  请先选择收货地址: 'Please select a shipping address first.',
  请选择支付方式: 'Please select a payment method.',
  '发票添加失败,请稍后再试': 'Failed to add invoice，please try again later.',
  全场满: 'Full site discount',
  全场: 'Full site',
  部分商品打: 'Discount on some items',
  部分商品减: 'Reduction on some items',
  部分商品满: 'Full discount on some items',
  元打: 'Yuan discount',
  折: 'Discount',
  元: 'Yuan',
  元减: 'Yuan reduction',
  添加成功: 'Added successfully',
  添加失败: 'Failed to add',
  该商品暂时无货: 'This item is temporarily out of stock',
  请不要频繁发送请求: 'Please do not send requests frequently',
  '网络错误,没有获取到数据': 'Network error, data not obtained',
  '当前商品库存不足,请选择其他商品':
    'Insufficient stock of the current item, please select other items',
  请选择规格属性: 'Please select specifications and attributes',
  提交订单: 'Submit order',
  修改地址: 'Modify address',
  联系方式: 'Contact information',
  送货地址: 'Delivery address',
  请输入联系人姓名: "Please enter the contact person's name",
  请输入联系人电话: "Please enter the contact person's phone number",
  请选择收货地址: 'Please select a delivery address',
  选择收货地址: 'Select a delivery address',
  请输入详细地址: 'Please enter a detailed address',
  本次未开具发票: 'Invoice not issued this time',
  未开具发票: 'Invoice not issued this time',
  上殊房欢迎您: 'Welcome to Shangshuroom',
  手机号码: 'Mobile phone number',
  获取验证码: 'Get verification code',
  请正确填写手机号码和验证码:
    'Please enter the correct mobile phone number and verification code',
  请输入原始手机号码: 'Please enter the original mobile phone number',
  请输入要绑定的手机号码: 'Please enter the mobile phone number to be bound',
  请输入验证码: 'Please enter the verification code',
  请输入正确的验证码: 'Please enter the correct verification code',
  "是否同意'用户协议'与'隐私政策'":
    "Do you agree to the 'User Agreement' and 'Privacy Policy'?",
  登录即代表阅读并同意:
    'Logging in signifies reading and agreeing to the User Agreement and Privacy Policy.',
  用户使用协议: 'User Agreement',
  与: 'and',
  立即使用: 'Start using immediately',
  返回上一页: 'Return to the previous page',
  已关闭: 'Closed',
  已退款: 'Refunded',
  交易成功: 'Transaction successful',
  退款中: 'Refund in process',
  修改用户信息: 'Modify user information',
  请输入用户昵称: 'Please enter the user nickname',
  确定: 'Confirm',
  取消: 'Cancel',
  请选择: 'Please select',
  男: 'Male',
  女: 'Female',
  请输入用户年龄: "Please enter the user's age",
  未实名: 'Not verified',
  已实名: 'Verified',
  新建地址: 'New address',
  默认地址: 'Default address',
  '(实名信息一经认证不可更改，如有疑问请联系上殊房客服)':
    '(Real-name information cannot be changed once verified, please contact Shangshuroom customer service if you have any questions)',
  '网络错误，请稍后再试': 'Network error, please try again later',
  订单已超时自动取消: 'Order automatically canceled due to timeout',
  订单支付失败: 'Order payment failed',
  服务器错误: 'Server error',
  已退出登录: 'Logged out',
  请填写收货地址: 'Please fill in the delivery address',
  '获取列表错误，请稍后再试': 'Failed to get the list, please try again later',
  兑换成功: 'Redemption successful',
  兑换失败: 'Redemption failed',
  这已经是第一篇了: 'This is already the first one',
  这已经是最后一篇了: 'This is already the last one',
  '请求超时，请稍后重试': 'Request timeout, please try again',
  请认真填写信息: 'Please fill in the information carefully',
  '请求失败,请稍后再试': 'Request failed，please try again later',
  请填写完整信息: 'Please fill in the information completely',
  请将信息规范填写:
    'Please fill in the information according to the specifications',
  请认真填写用户信息: 'Please fill in the user information carefully',
  '认证失败,请稍后重试': 'Authentication failed, please try again',
  '验证失败,请稍后重试': 'Verification failed, please try again',
  修改邮箱成功: 'Email modification successful',
  请认真填写邮箱: 'Please fill in the email carefully',
  修改用户信息成功: 'User information modification successful',
  '获取用户信息失败,请重新刷新':
    'Failed to get user information, please refresh and try again',
  修改用户信息失败: 'User information modification failed',
  只可以上传一个图片: 'Only one image can be uploaded',
  '上传头像图片只能是 JPG 格式!': 'The avatar image can only be in JPG format!',
  '上传头像图片大小不能超过 5MB!': 'The avatar image size cannot exceed 5MB!',
  上殊房隐私政策: 'Shangshuroom Privacy Policy.',
  没有使用优惠券: 'No coupons used',
  已添加到收藏列表: 'Added to favorites list',
  已取消到收藏列表: ' Removed from favorites list',
  未知: 'Unknown',
  缺货: 'Out of stock',
  删除: 'Delete',
  查看购物车: 'View cart',
  运费在结账时计算: 'Shipping calculated at checkout',
  用户昵称: ' Username',
  复制成功: ' Copy successful',
  复制失败: 'Copy failed',
  完成: 'Complete',
  管理: 'Manage',
  删除收藏列表成功: 'Successfully removed from favorites list',
  删除成功: 'Deletion successful',
  修改成功: 'Modification successful',
  修改失败: 'Modification failed',
  '修改失败,请稍后再试': 'Modification failed, please try again later',
  还没有失效优惠券: 'No expired coupons yet',
  还没有优惠券: 'No coupons yet',
  还没有用过的优惠券: 'No coupons used yet',
  该优惠券仅仅适用于: 'This coupon is only applicable to',
  满: 'when you spend',
  暂未使用优惠券: 'No coupons used yet',
  已使用部分商品满: ' Used for partial products full',
  折优惠券: 'Discount coupon',
  元优惠券: 'Currency coupon',
  已使用部分商品减: 'Used for partial products reduction',
  已使用部分商品打: 'Used for partial products discount',
  已使用全场满: 'Used for all products full',
  已使用全场减: 'Used for all products reduction',
  已使用全场打: 'Used for all products discount',
  订单详情: 'Order details',
  分享: 'Share',
  立即分享给好友: 'Share with friends now',
  复制链接: 'Copy Link',
  微博: 'Weibo',
  剩余: 'Remaining',
  ...enLocale,
}

export default en
