import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './router.config.js'
import { cookieMethods } from '@/util/js/methods.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  let a = cookieMethods.get('token')
  if (to.path == '/login' && a) {
    next('/')
  } else {
    if (to.meta.register && a) {
      next()
    } else {
      if (!to.meta.register) {
        next()
      } else {
        next({
          path: '/login',
          query: {
            path: to.path,
          },
        })
      }
    }
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
export default router
