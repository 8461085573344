import deLocale from 'element-ui/lib/locale/lang/de'

const de = {
  上殊房: 'Shangshu Room',
  首页: 'Startseite',
  画廊: 'Galerie',
  艺术家: 'Künstler',
  我的: 'Mein',
  关于: 'Über',
  问题: 'Frage',
  联系我们: 'Kontaktiere uns',
  请输入要查询的内容:
    'Bitte geben Sie den Inhalt ein, den Sie abfragen möchten.',
  查看作品: 'Werke anzeigen',
  继续阅读: 'Weiterlesen',
  上殊房携手知名艺术家共同制作:
    'Shangshu Room arbeitet mit bekannten Künstlern zusammen, um gemeinsame Produktionen zu realisieren.',
  让我们走进每个作品背后的故事:
    'Lassen Sie uns die Geschichten hinter jedem Werk erkunden.',
  浏览全部: 'Alle durchsehen',
  推荐: 'Empfehlungen',
  筛选: 'Filtern',
  重置: 'Zurücksetzen',
  保存: 'Speichern',
  上殊房背后的艺术家: 'Die Künstler Hinter Shangshu Room',
  猜你喜欢: 'Vorschläge für dich.',
  加入购物车: 'In den Warenkorb legen',
  立即购买: 'Jetzt kaufen',
  多维度展示: 'Multi-dimensionale Darstellung',
  '全方位的、立体的体验视觉之美':
    'Ein umfassendes, dreidimensionales Erleben der Schönheit visueller Eindrücke',
  品质保证: 'Qualitätsgarantie',
  限量发售: 'Limitierte Auflage',
  同多位艺术家合作: 'Zusammenarbeit mit mehreren Künstlern',
  携手多位艺术家共同打造更多精美作品:
    'Gemeinsam mit mehreren Künstlern viele exquisite Werke schaffen.',
  订阅我们的公众号: 'Abonnieren Sie unseren öffentlichen Account.',
  更多精彩上新第一时间通知到你:
    'Benachrichtigen Sie Sie sofort über mehr aufregende Neuheiten.',
  信息: 'Informationen',
  服务条款: 'Nutzungsbedingungen',
  隐私政策: 'Datenschutzrichtlinie',
  您的购物车还是空的: 'Ihr Einkaufswagen ist noch leer.',
  继续浏览: 'Weiter stöbern',
  已售罄: 'Ausverkauft',
  作者: 'Autor',
  'buy now': 'jetzt kaufen',
  用户登录: 'Benutzeranmeldung',
  手机号: 'Telefonnummer',
  验证码: 'Verifizierungscode',
  登录: 'Anmelden',
  登录即代表阅读并同意用户使用协议与隐私政策:
    'Das Einloggen bedeutet, dass Sie die Nutzungsbedingungen und Datenschutzrichtlinien gelesen haben und diesen zustimmen.',
  有货: 'Auf Lager',
  '请选择 尺寸': 'Bitte Größe wählen.',
  购买数量: 'Kaufmenge',
  尺寸: 'Größe',
  '本店部分商品为数字产品，一经购买，概不退换':
    'Einige Artikel in unserem Geschäft sind digitale Produkte. Nach dem Kauf ist eine Rückgabe oder Umtausch ausgeschlossen.',
  共计: 'Insgesamt',
  '￥': '',
  您的购物车内有: 'In Ihrem Einkaufswagen befinden sich ',
  件商品: ' Artikel.',
  设置: 'Einstellungen',
  我的订单: 'Meine Bestellungen',
  优惠券: 'Gutschein',
  我的收藏: 'Meine Favoriten',
  收货地址: 'Lieferadresse',
  在线客服: 'Online-Kundenservice',
  客服: 'Kundenservice',
  退出: 'Abmelden',
  全部: 'Alle',
  待支付: 'Ausstehende Zahlung',
  待发货: 'Ausstehender Versand',
  已发货: 'Versandt',
  已完成: 'Abgeschlossen',
  兑换: 'Einlösen',
  未使用: 'Nicht verwendet',
  已使用: 'Verwendet',
  已失效: 'Abgelaufen',
  请输入您的兑换码兑换线上优惠券:
    'Bitte geben Sie Ihren Gutscheincode ein, um den Online-Gutschein einzulösen.',
  请输入兑换码: 'Bitte geben Sie den Einlösungscode ein',
  全选: 'Alles auswählen',
  我的头像: 'Mein avatar',
  修改昵称: 'Spitznamen ändern',
  性别: 'Geschlecht',
  年龄: 'Alter',
  实名认证: 'Echtnamenauthentifizierung',
  绑定邮箱: 'E-Mail binden',
  退出登录: 'Abmelden',
  取消输入: 'Eingabe abbrechen',
  请输入您的邮箱: 'Bitte geben Sie Ihre E-Mail ein',
  提交信息: 'Informationen einreichen',
  杭州寸间大造文化发展有限公司: 'Hangzhou CunJian Kulturentwicklung GmbH',
  '联系电话：0571-89996203': 'Kontakttelefon: 0571-89996203',
  '传真：0571-89996203': 'Fax: 0571-89996203',
  '邮箱：gfxyzcosmos@gmail.com': 'E-Mail: gfxyzcosmos@gmail.com',
  '网站：https://shangshuroom.com': 'Webseite: https://shangshuroom.com',
  '当我订购艺术品时,我会得到什么?':
    'Was werde ich erhalten, wenn ich ein Kunstwerk bestelle?',
  '您会得到一个该艺术品的实物产品，产品会以精美的包装快递或者定制配送到您手里。':
    'Sie erhalten ein physisches Produkt des Kunstwerks, das Ihnen in exquisiter Verpackung per Kurier oder individueller Lieferung zugestellt wird.',
  '在哪里可以看到我的优惠券？': 'Wo kann ich meine Gutscheine sehen?',
  '注：如果您没有看见已领取的优惠券，可能因为：您领取时使用的账号与您本次登录的账号不一致。您之前领取的优惠券已过期/已使用。':
    'Hinweis: Wenn Sie die erhaltenen Gutscheine nicht sehen, kann es daran liegen, dass das Konto, mit dem Sie den Gutschein erhalten haben, nicht mit dem Konto übereinstimmt, mit dem Sie sich dieses Mal eingeloggt haben. Der Gutschein, den Sie zuvor erhalten haben, ist abgelaufen/benutzt worden.',
  '优惠券如何使用？': 'Wie benutzt man Gutscheine?',
  '1.优惠券分为：满减优惠券、现金券和折扣券。满减券需满足指定金额可用；':
    '1. Gutscheine werden unterteilt in: Rabattgutscheine, Bargutscheine und Rabattmarken. Rabattgutscheine können verwendet werden, wenn ein bestimmter Betrag erreicht ist;',
  '2.单笔订单只能使用1张优惠券，不支持同时使用多张，用券后差额不找零，不兑换现金。':
    '2. Pro Bestellung kann nur ein Gutschein verwendet werden. Mehrere Gutscheine können nicht gleichzeitig verwendet werden. Die Differenz wird nicht in bar erstattet, wenn der Gutschein verwendet wird.',
  '3. 使用优惠券的订单若产生退货，需订单内所有商品均退货退款成功，且优惠券仍在有效期内，可返回优惠券，退回后的优惠券有效期不变。':
    '3. Wenn eine Bestellung mit einem Gutschein zurückgegeben wird, müssen alle Artikel in der Bestellung erfolgreich zurückgegeben werden, und der Gutschein muss noch innerhalb der Gültigkeitsdauer sein, um zurückgegeben zu werden. Die Gültigkeitsdauer des zurückgegebenen Gutscheins bleibt unverändert.',
  '* 若只申请部分退款且退款成功，优惠券将不予退回。':
    'Wenn nur eine teilweise Rückerstattung beantragt und erfolgreich zurückerstattet wird, wird der Gutschein nicht zurückgegeben.',
  '* 退回优惠券如已过期，则不再补发。':
    'Wenn der zurückgegebene Gutschein abgelaufen ist, wird er nicht neu ausgestellt.',
  '4.请在有效期内使用优惠券，未使用的优惠券过期后，将自动作废。':
    '4. Bitte verwenden Sie den Gutschein innerhalb der Gültigkeitsdauer. Unbenutzte Gutscheine werden nach Ablauf automatisch ungültig.',
  '【上殊房】——听见艺术最细腻的声音':
    '【Shangshu Room】—— Die zartesten Klänge der Kunst hören',
  发票类型: 'Rechnungstyp',
  个人或事业单位: 'Person oder Unternehmen',
  企业: 'Unternehmen',
  填写需要开具发票的企业名称: 'Geben Sie den Firmennamen für die Rechnung ein',
  纳税人识别号: 'Steuernummer',
  '请输入您的邮箱,稍后我们会将发票发送至您的邮箱':
    'Bitte geben Sie Ihre E-Mail-Adresse ein, wir senden Ihnen die Rechnung später per E-Mail',
  请输入注册地址: 'Geben Sie die registrierte Adresse ein',
  请输入开户行: 'Geben Sie den Banknamen ein',
  请输入开户账号: 'Geben Sie die Bankkontonummer ein',
  开户账号: 'Bankkontonummer',
  开户行: 'Bankname',
  注册地址: 'Registrierte Adresse',
  接收邮箱: 'Empfangs-E-Mail',
  税号: 'Steuernummer',
  发票抬头: 'Rechnungstitel',
  抬头类型: 'Rechnungstitel-Typ',
  增值税电子普通发票: 'MwSt. Elektronische Normale Rechnung',
  不开发票: 'Keine Rechnung ausstellen',
  安装费: 'Installationsgebühr',
  配送服务: 'Lieferdienst',
  微信: 'WeChat',
  支付宝: 'Alipay',
  支付方式: 'Zahlungsmethode',
  实付: 'Bezahlter Betrag',
  查看详情: 'Details anzeigen',
  取消订单: 'Bestellung stornieren',
  立即支付: 'Jetzt bezahlen',
  订单编号: 'Bestellnummer',
  小计: 'Zwischensumme',
  开具发票: 'Rechnung ausstellen',
  '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前。而“殊”则是特别，将每一件作品的殊胜之处，背后的细腻情感传达到您的心里。众多艺术家们将与上殊房一起缔造一个更丰富多彩的多维空间，呈现众多杰出艺术作品的进行时和在新的宇宙空间里的将来时。希望喜欢、热爱艺术的你，成为上殊房的一份子，一起去聆听艺术最细腻的声音。将更多的欢喜和慰藉带给自己，也带给身边的人。':
    '„Shang“ bedeutet zu beginnen, zu handeln und aktiv zu werden. Wir sind bestrebt, jedes Kunstwerk auf multidimensionale Weise vor Ihren Augen zu präsentieren. „Shu“ bedeutet besonders und vermittelt die einzigartigen Aspekte und zarten Emotionen hinter jedem Werk in Ihr Herz.Viele Künstler werden sich Shangshu Room anschließen, um einen farbenfroheren multidimensionalen Raum zu schaffen, der zahlreiche herausragende Kunstwerke im Entstehen und im zukünftigen Raum eines neuen Universums präsentiert. Wir hoffen, dass Sie, die Kunst lieben und schätzen, ein Teil von Shangshu Room werden und gemeinsam die zartesten Klänge der Kunst hören. Bringen Sie mehr Freude und Trost für sich selbst und für die Menschen um Sie herum.',
  确定要删除该商品吗:
    'Sind Sie sicher, dass Sie diesen Artikel löschen möchten?',
  没有搜索到匹配的数据: 'Keine passenden Daten gefunden.',
  '领取优惠券之后，请您登录上殊房，点击“我的”，进入“优惠券”，​查看您已领取的优惠券。我的卡券分为三个模块：未使用、已使用、已失效。':
    'Nach dem Einlösen des Coupons loggen Sie sich bitte in Shangshuroom ein, klicken Sie auf "Mein", gehen Sie zu "Coupons", um Ihre eingelösten Coupons zu sehen. Meine Coupons sind in drei Abschnitte unterteilt: Unbenutzt, Benutzt und Abgelaufen.',
  '移动电话：15925933033/18605077656': 'Mobiltelefon: 15925933033/18605077656',
  '地址：浙江省杭州市上城区越秀维多利中心15A02':
    'Adresse: Raum 15A02, Yuexiu Vitoria Center, Shangcheng District, Hangzhou Stadt, Zhejiang Provinz',
  请先完成实名:
    'Bitte vervollständigen Sie zuerst die Realnamen-Verifizierung.',
  请先支付该订单: 'Bitte zahlen Sie zuerst für diese Bestellung.',
  '确认订单信息错误,请稍后再试':
    'Bestellinformationen fehlerhaft, bitte versuchen Sie es später erneut.',
  请先选择收货地址: 'Bitte wählen Sie zuerst eine Lieferadresse.',
  请选择支付方式: 'Bitte wählen Sie eine Zahlungsmethode.',
  '发票添加失败,请稍后再试':
    'Rechnung hinzufügen fehlgeschlagen, bitte versuchen Sie es später erneut.',
  全场满: 'Rabatt auf das gesamte Sortiment',
  全场: 'Gesamtes Sortiment',
  部分商品打: 'Rabatt auf einige Artikel',
  部分商品减: 'Rabatt auf einige Artikel',
  部分商品满: 'Rabatt auf einige Artikel',
  元打: 'Yuan Rabatt',
  折: 'Rabatt',
  元: 'Yuan',
  元减: 'Yuan Rabatt',
  添加成功: 'Erfolgreich hinzugefügt',
  添加失败: 'Hinzufügen fehlgeschlagen',
  该商品暂时无货: 'Dieser Artikel ist vorübergehend nicht auf Lager',
  请不要频繁发送请求: 'Bitte senden Sie keine häufigen Anfragen',
  '网络错误,没有获取到数据': 'Netzwerkfehler, Daten nicht abgerufen',
  '当前商品库存不足,请选择其他商品':
    'Unzureichender Lagerbestand des aktuellen Artikels, bitte wählen Sie andere Artikel',
  请选择规格属性: 'Bitte wählen Sie Spezifikationen und Attribute',
  提交订单: 'Bestellung absenden',
  修改地址: 'Adresse ändern',
  联系方式: 'Kontaktinformationen',
  送货地址: 'Lieferadresse',
  请输入联系人姓名: 'Bitte geben Sie den Namen des Kontakts ein',
  请输入联系人电话: 'Bitte geben Sie die Telefonnummer des Kontakts ein',
  请选择收货地址: 'Bitte wählen Sie eine Lieferadresse',
  选择收货地址: 'Bitte wählen Sie eine Lieferadresse',
  请输入详细地址: 'Bitte geben Sie eine detaillierte Adresse ein',
  本次未开具发票: 'Rechnung dieses Mal nicht ausgestellt',
  未开具发票: 'Rechnung dieses Mal nicht ausgestellt',
  上殊房欢迎您: 'Willkommen bei Shangshuroom',
  手机号码: 'Mobiltelefonnummer',
  获取验证码: 'Bestätigungscode erhalten',
  请正确填写手机号码和验证码:
    'Bitte geben Sie die richtige Mobiltelefonnummer und den Bestätigungscode ein',
  请输入原始手机号码:
    'Bitte geben Sie die ursprüngliche Mobiltelefonnummer ein',
  请输入要绑定的手机号码:
    'Bitte geben Sie die zu bindende Mobiltelefonnummer ein',
  请输入验证码: 'Bitte geben Sie den Bestätigungscode ein',
  请输入正确的验证码: 'Bitte geben Sie den richtigen Bestätigungscode ein',
  "是否同意'用户协议'与'隐私政策'":
    "Stimmen Sie der 'Nutzungsvereinbarung' und der 'Datenschutzrichtlinie' zu?",
  登录即代表阅读并同意:
    'Das Einloggen bedeutet, dass Sie die Nutzungsvereinbarung und die ',
  用户使用协议: 'Nutzungsvereinbarung',
  与: 'und',
  立即使用: 'Sofort verwenden',
  返回上一页: 'Zurück zur vorherigen Seite',
  已关闭: 'Geschlossen',
  已退款: 'Erstattet',
  交易成功: 'Transaktion erfolgreich',
  退款中: 'Rückerstattung läuft',
  修改用户信息: 'Benutzerinformationen ändern',
  请输入用户昵称: 'Bitte geben Sie den Benutzernamen ein',
  确定: 'Bestätigen',
  取消: 'Abbrechen',
  请选择: 'Bitte wählen',
  男: 'Männlich',
  女: 'Weiblich',
  请输入用户年龄: 'Bitte geben Sie das Alter des Benutzers ein',
  未实名: 'Nicht verifiziert',
  已实名: 'Verifiziert',
  新建地址: 'Neue Adresse',
  默认地址: 'Standardadresse',
  '(实名信息一经认证不可更改，如有疑问请联系上殊房客服)':
    '(Realnamen-Informationen können nach der Verifizierung nicht mehr geändert werden, bitte kontaktieren Sie den Shangshuroom-Kundendienst bei Fragen)',
  '网络错误，请稍后再试': 'Netzwerkfehler',
  订单已超时自动取消:
    'Bestellung automatisch wegen Zeitüberschreitung storniert',
  订单支付失败: 'Bestellzahlung fehlgeschlagen',
  服务器错误: 'Serverfehler',
  已退出登录: 'Abgemeldet',
  请填写收货地址: 'Bitte füllen Sie die Lieferadresse aus',
  '获取列表错误，请稍后再试':
    'Fehler beim Abrufen der Liste, bitte versuchen Sie es später erneut',
  兑换成功: 'Einlösung erfolgreich',
  兑换失败: 'Einlösung fehlgeschlagen',
  这已经是第一篇了: 'Dies ist bereits das erste',
  这已经是最后一篇了: 'Dies ist bereits das letzte',
  '请求超时，请稍后重试':
    'Anforderungszeitüberschreitung, bitte erneut versuchen',
  请认真填写信息: 'Bitte füllen Sie die Informationen sorgfältig aus',
  '请求失败,请稍后再试':
    'Anfrage fehlgeschlagen, bitte versuchen Sie es später erneut',
  请填写完整信息: 'Bitte füllen Sie die Informationen vollständig aus',
  请将信息规范填写:
    'Bitte füllen Sie die Informationen gemäß den Spezifikationen aus',
  请认真填写用户信息:
    'Bitte füllen Sie die Benutzerinformationen sorgfältig aus',
  '认证失败,请稍后重试':
    'Authentifizierung fehlgeschlagen, bitte erneut versuchen',
  '验证失败,请稍后重试': 'Überprüfung fehlgeschlagen, bitte erneut versuchen',
  修改邮箱成功: 'E-Mail-Änderung erfolgreich',
  请认真填写邮箱: 'Bitte füllen Sie die E-Mail sorgfältig aus',
  修改用户信息成功: 'Benutzerinformationen erfolgreich geändert',
  '获取用户信息失败,请重新刷新':
    'Fehler beim Abrufen der Benutzerinformationen, bitte aktualisieren und erneut versuchen',
  修改用户信息失败: 'Änderung der Benutzerinformationen fehlgeschlagen',
  只可以上传一个图片: 'Es kann nur ein Bild hochgeladen werden',
  '上传头像图片只能是 JPG 格式!': 'Das Profilbild darf nur im JPG-Format sein!',
  '上传头像图片大小不能超过 5MB!':
    'Die Größe des Profilbildes darf 5 MB nicht überschreiten!',
  上殊房隐私政策: 'Shangshuroom Datenschutzrichtlinie.',
  没有使用优惠券: 'Keine Gutscheine verwendet',
  已添加到收藏列表: 'Zur Favoritenliste hinzugefügt',
  已取消到收藏列表: 'Aus der Favoritenliste entfernt',
  未知: 'Unbekannt',
  缺货: 'Ausverkauft',
  删除: 'Löschen',
  查看购物车: ' Warenkorb ansehen',
  运费在结账时计算: 'Versandkosten beim Checkout berechnet',
  用户昵称: 'Benutzername',
  复制成功: 'Kopieren erfolgreich',
  复制失败: 'Kopieren fehlgeschlagen',
  完成: 'Fertig',
  管理: 'Verwalten',
  删除收藏列表成功: 'Erfolgreich aus der Favoritenliste entfernt',
  删除成功: ' Erfolgreich gelöscht',
  修改成功: 'Erfolgreich geändert',
  修改失败: 'Änderung fehlgeschlagen',
  '修改失败,请稍后再试':
    'Änderung fehlgeschlagen, bitte später erneut versuchen',
  还没有失效优惠券: 'Noch keine abgelaufenen Gutscheine',
  还没有优惠券: 'Noch keine Gutscheine',
  还没有用过的优惠券: 'Noch keine verwendeten Gutscheine',
  该优惠券仅仅适用于: 'Dieser Gutschein gilt nur für',
  满: 'bei einem Einkauf von mindestens',
  暂未使用优惠券: 'Noch keine Gutscheine verwendet',
  已使用部分商品满: ' Für teilweise Produkte verwendet',
  折优惠券: 'Rabattgutschein',
  元优惠券: 'Währungsgutschein',
  已使用部分商品减: 'Für teilweise Produkte verwendet',
  已使用部分商品打: 'Für teilweise Produkte verwendet',
  已使用全场满: ' Für alle Produkte verwendet',
  已使用全场减: 'Für alle Produkte verwendet',
  已使用全场打: ' Für alle Produkte verwendet',
  订单详情: 'Bestelldetails',
  分享: 'Aktie',
  立即分享给好友: 'Jetzt mit Freunden teilen',
  复制链接: 'Link kopieren',
  微博: 'Weibo',
  剩余: 'Übrig',
  ...deLocale,
}

export default de
